// Footer.js

import React from 'react';
import './Footer.css'; 

function Footer() {
    return (
        <footer className="footer">
            <p>Recommend Me A Movie. All rights reserved. &copy; 2023</p>
        </footer>
    )
}

export default Footer;
