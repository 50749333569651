import React from "react";
import './Contact.css';

function Contact() {

  return (
    <div className="contact-container">
      <div id="map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2701.157176786567!2d8.491911912114208!3d47.38936607105045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900bca0b47b1ed%3A0x4435dc85e08cbb21!2sHohlstrasse%20500%2C%208048%20Z%C3%BCrich%2C%20Switzerland!5e0!3m2!1sen!2sgr!4v1685374892937!5m2!1sen!2sgr"
          height="450"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
}

export default Contact;
