import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';


var firebaseConfig = {
  apiKey: "AIzaSyAq92nL7QVL_LEaU_jjDhhbgfLQRun6X2U",
  authDomain: "movie-recommender-386814.firebaseapp.com",
  projectId: "movie-recommender-386814",
  storageBucket: "movie-recommender-386814.appspot.com",
  messagingSenderId: "6951355054",
  appId: "1:6951355054:web:d513c0329003636da8e33f",
  measurementId: "G-FQFTMDLD2X"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
export default firebase;
