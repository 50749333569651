// UserProvider.js
import React, { useState, useEffect } from 'react';
import firebase from './firebaseConfig';
import UserContext from './UserContext';

const signInWithGoogle = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider).then(function(result) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = result.credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      
      // Get reference to the user document.
      var userDocRef = firebase.firestore().collection("users").doc(user.uid);

      // Get the document.
      userDocRef.get().then((docSnapshot) => {
        // If document does not exist, create it.
        if (!docSnapshot.exists) {
          userDocRef.set({
            name: user.displayName,
            email: user.email,
            providerId: user.providerId,
            displayName: user.displayName,
            loginTime: new Date().toISOString(),
            emailListSubscribed: true,
            // ... any other data you want to store ...
          })
          .then(() => {
            console.log("Document successfully written!");
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
        }
      });
    }).catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
    });
}

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(setUser);

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ user, signInWithGoogle }}>
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;
