import React, { useState } from 'react';
import Form from './components/Form';
import MovieList from './components/MovieList';
import LoadingSpinner from './components/LoadingSpinner';
import Login from './components/Login';
import Header from './components/Header';
import Footer from './components/Footer';
import Contact from './components/Contact';
import AboutUs from './components/AboutUs';
import PastRecommendations from './components/PastRecommendations';
import UserProvider from './UserProvider';


import MovieCarousel from './components/MovieCarousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import './App.css';


function App() {
  const defaultRecommendedMovies=['The Godfather (1972) Imdb: 9.2 (Rotten Tomatoes: 98%)','Inception (2010) Imdb: 8.8 (Rotten Tomatoes: 87%)','Get Out (2017) Imdb: 7.7 (Rotten Tomatoes: 99%)']
  let [recommendedMovies, setRecommendedMovies] = useState(defaultRecommendedMovies);
  const [isLoading, setIsLoading] = useState(false);


  return (
    <UserProvider>
    <div className="App">
      <Header />
      <h1 className="top" >AI-Powered Movie Recommender</h1>
      <Login></Login>
      <h3>Fill in your preferences and generate personalized reccommendations</h3>
      <Form setRecommendedMovies={setRecommendedMovies} setIsLoading={setIsLoading} />
      {isLoading ? <LoadingSpinner /> : <MovieList recommendedMovies={recommendedMovies} />}
<PastRecommendations></PastRecommendations>
      <MovieCarousel></MovieCarousel>
    <AboutUs />
    <Contact></Contact>
      <Footer />
    </div>
    </UserProvider>
  );
}

export default App;
