// Login.js
import React, { useContext } from 'react'; // <--- useContext is imported here
import './Login.css'; 
import UserContext from '../UserContext';


function Login() {
  const { user, signInWithGoogle } = useContext(UserContext);

  return (
    <div>
       {user ? (
         <div className="user-greeting"> Nice to have you {user.displayName} 👋</div>
       ) : (
         <div className="google-btn" onClick={signInWithGoogle}>
           <img className="google-icon"
                src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" 
                alt="Google sign-in"/>
           <span className="google-sign-in">Sign in with Google</span>
         </div>
       )}
    </div>
  );
}

export default Login;