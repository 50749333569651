let apiUrl;

if (process.env.NODE_ENV === 'development') {
//   apiUrl = 'http://localhost:5001';
  apiUrl = 'http://localhost:5000/movie-recommender-386814/us-central1/api'
//   apiUrl = 'https://us-central1-movie-recommender-386814.cloudfunctions.net/api';  // replace with your Firebase function URL
} else {
  apiUrl = 'https://us-central1-movie-recommender-386814.cloudfunctions.net/api';  // replace with your Firebase function URL
}

export default {
  apiUrl
};