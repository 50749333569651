import React from 'react';
import './Header.css'; 
import { Link } from 'react-scroll';


function Header() {
    return (
        <header className="header">
            <nav>
                <ul className="nav_links">
                    <li><Link to="top" spy={true} smooth={true}>Home</Link></li>
                    <li><Link to="about" spy={true} smooth={true}>About</Link></li>
                    <li><Link to="about" spy={true} smooth={true}>Contact</Link></li>
                </ul>
            </nav>
        </header>
    )
}

export default Header;
