import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './MovieCarousel.css'; 


function MovieCarousel() {
  const movieImages = [
    {
      url: 'https://assets3.thrillist.com/v1/image/1656352/1200x630/flatten;crop_down;webp=auto;jpeg_quality=70',
      title: 'The Great Gatsby'
    },
    {
      url: 'https://cdn.britannica.com/55/188355-050-D5E49258/Salvatore-Corsitto-The-Godfather-Marlon-Brando-Francis.jpg',
      title: 'The Godfather'
    },
    {
      url: 'https://cdn.britannica.com/05/174805-050-5ADF3697/Edwin-Epps-Michael-Fassbender-slaves-12-Years.jpg',
      title: '12 Years a Slave'
    },
    {
      url: 'https://cdn.britannica.com/46/90646-050-11D0C1AE/Scene-The-Man-Who-Knew-Too-Much.jpg?w=300',
      title: 'The Man Who knew Too Much'
    },

    // more movie images...
];

return (
  <div className="carousel-container">
    <Carousel autoPlay interval={2500} infiniteLoop>
      {movieImages.map((movie, index) => (
        <div key={index}>
          <img src={movie.url} alt={movie.title} />
          <p className="legend">{movie.title}</p>
        </div>
      ))}
    </Carousel>
    </div>
);
}

export default MovieCarousel;
