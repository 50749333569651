import React, { useState, useEffect } from 'react';
import './Form.css';
import config from '../config';
import firebase from '../firebaseConfig'
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/analytics';

const analytics = firebase.analytics();

const Form = ({ setRecommendedMovies, setIsLoading }) => {
  const [user, setUser] = useState(null);
  const [favouriteActorName, setFavouriteActorName] = useState('');
  const [favoriteGenre, setFavoriteGenre] = useState('');
  const [movieRatingConsiderationScore, setmovieRatingConsiderationScore] = useState('');

  // Listening for auth state changes
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      setUser(user);
      if (user) {
        // User is signed in.
        const docRef = firebase.firestore().collection("users").doc(user.uid);
        docRef.get().then((doc) => {
          if (doc.exists) {
            // Set the input fields to the stored values
            const data = doc.data();
            setFavouriteActorName(data.favouriteActorName || '');
            setFavoriteGenre(data.favoriteGenre || '');
            setmovieRatingConsiderationScore(data.movieRatingConsiderationScore || '');
          }
        }).catch((error) => {
          console.log("Error getting document:", error);
        });
      } else {
        // No user is signed in.
        setFavouriteActorName('');
        setFavoriteGenre('');
        setmovieRatingConsiderationScore('');
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

async function getRecommendedMovies(userData){
  setIsLoading(true);
  let movieData =['','','']
      try {
          let userUid = user === null ? 'None' : user.uid;
          const response = await fetch(`${config.apiUrl}/recommendation/${userUid}`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',},
            body: JSON.stringify(userData),
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          movieData = await response.json();
      } catch (error) {
        console.error('Error fetching movie recommendations:', error);
      } finally {
        setRecommendedMovies(movieData);
        setIsLoading(false);
      }
}

  const handleSubmit = async event => {
    event.preventDefault();
    analytics.logEvent('generate_movies_button_clicked');
    const userData = {
      favouriteActorName,
      favoriteGenre,
      movieRatingConsiderationScore,
    };
    if (user) {
      // Save the user's form data to Firestore
      firebase.firestore().collection("users").doc(user.uid).set(userData, { merge: true })
        .then(() => console.log("Document successfully written!"))
        .catch((error) => console.error("Error writing document: ", error));
    }
    await getRecommendedMovies(userData);
  };

  return (
    <form onSubmit={handleSubmit} className="form-grid">
        <div className="input-field">
          <label>
            Favourite Actor
            <input
              type="text"
              value={favouriteActorName}
              placeholder="..Leonardo DiCaprio" 
              onChange={e => setFavouriteActorName(e.target.value)}
            />
          </label>
        </div>
        <div className="input-field">
          <label>
            Favourite Genre
            <input
              type="text"
              value={favoriteGenre}
              placeholder="..Thriller" 
              onChange={e => setFavoriteGenre(e.target.value)}
            />
          </label>
        </div>
        <div className="input-field">
          <label>
            How important are IMDB & Rotten Tomatoes ratings to you? (0-10)
            <input
              type="number"
              min="1" 
              max="10"
              placeholder="6" 
              value={movieRatingConsiderationScore}
              onChange={e => setmovieRatingConsiderationScore(e.target.value)}
            />
          </label>
        </div>
       <button className="generateMoviesButton" type="submit" >Generate list of movies</button>
    </form>
    
  );
};

export default Form;
