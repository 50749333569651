import React from 'react';
import './AboutUs.css';

function AboutUs() {
    return (
        <div className="about">
            <h2>About Us</h2>
            <p>Our team of movie buffs and AI enthusiasts have come together to build a unique platform that suggests movies based on your preferences. Our innovative system considers your favourite actors, preferred movie genres, and how much weight you put on ratings. We aim to make your movie selection process enjoyable, easy, and personalized. We're continually updating and improving our system to provide you with the best recommendations. Stay tuned!</p>
        </div>
    );
}

export default AboutUs;
