import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import UserContext from '../UserContext';  
import config from '../config';

const PastRecommendations = () => {
  const { user } = useContext(UserContext);
  const [pastRecommendations, setPastRecommendations] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPastRecommendations = async () => {
      let userUid = user === null ? 'None' : user.uid;
      if (userUid === 'None') {
        setIsLoading(false);
        return;
      }
      try {
        const response = await fetch(`${config.apiUrl}/past-recommendations/${userUid}`);
        if (!response.ok) { 
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setPastRecommendations(data);
      } catch (error) {
        console.error('Error fetching past recommendations:', error);
      }
      setIsLoading(false);
    };
    fetchPastRecommendations();
  }, [user]);

  if (isLoading) {
    return <div>Loading Past Recommendations...</div>;
  }
  if (!user) {
    return <div>Make sure to login to access your past movie recommendations</div>
  }

  const movieOptions = pastRecommendations?.map(movie => ({
    value: movie,
    label: `${movie}`,
  }));

  return (
    <div>
      <h2>Past Recommendations</h2>
      <Select 
        options={movieOptions} 
        isSearchable={true} 
        placeholder="View history of recommended movies..."
      />
    </div>
  );
};

export default PastRecommendations;
